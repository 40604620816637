
import {Options, Vue} from 'vue-class-component';
import ProductionTemplate from "@/components/ProductionTemplate.vue";
import ProductIntroduceData from "@/types/ProductIntroduceData";

@Options<MetaSoAI>({
  components: {
    ProductionTemplate
  },
  props: {}
})
export default class MetaSoAI extends Vue {
  introduce:ProductIntroduceData = {
    name:'秘塔AI搜索',
    profile:'没有广告，直达结果',
    buttons:{
      experience:'https://metaso.cn',
    },
    features:[
      {
        svgIconName: 'search',
        feature:{
          title:'全网搜索',
          desc:[
            '用户问题提供直接、精准的答案，并包含引用源',
            '自动生成大纲、思维导图、相关事件和人物',
            '一键导出搜索报告，支持Word和PDF格式'
          ]
        }
      },
      {
        svgIconName: 'academic',
        feature:{
          title:'学术搜索',
          desc:[
            '在数亿篇文献中筛选有用信息，助力学术研究',
            '快速提取摘要等关键信息，在搜索页清晰展示'
          ]
        }
      },
      {
        svgIconName: 'lap',
        feature:{
          title:'使用场景',
          desc:[
            '所有需要查找信息的人',
            '所有需要整理信息的人',
          ]
        }
      }
    ],
    psText:'使用开通'
  }
}
