
import {Options, Vue} from 'vue-class-component';
import ReactScrollImage from "@/components/ReactScrollImage.vue";
import Footer from "@/components/Footer.vue";
import anime from "animejs";
import {isMobile} from "@/utils";
@Options<About>({
  components: {
    ReactScrollImage,
    Footer
  },
  props: {}
})
export default class About extends Vue {
  observer?:IntersectionObserver;

  mounted(){
    const groupProfile = this.$refs.groupProfile as HTMLElement;
    const formersDom = groupProfile.querySelectorAll('.agp-item');
    anime.set(formersDom,{
      opacity:0
    })
    this.observer = new IntersectionObserver(([entry],observer)=>{
      console.log('entry.intersectionRatio0',entry.intersectionRatio,entry.target)
      if(entry.intersectionRatio < (isMobile() ?  0.1 : .7)) return;

      const tl = anime.timeline({
        duration:600,
        easing:'easeOutCubic',
      });

      if(isMobile()){
        tl.add({
          targets:formersDom.item(0),
          translateY:[-100,0],
          opacity:1,
        })
        .add({
          targets:formersDom.item(1),
          opacity:1,
          translateY:[-100,0],
        },700)
        .add({
          targets:formersDom.item(2),
          translateY:[-100,0],
          opacity:1,
        },900);
      }else{
        tl.add({
          targets:formersDom.item(1),
          translateY:[-100,0],
          opacity:1,
        })
        .add({
          targets:formersDom.item(0),
          opacity:1,
          translateX:[200,0],
        },1100)
        .add({
          targets:formersDom.item(2),
          translateX:[-200,0],
          opacity:1,
        },700);
      }
      observer.disconnect();
    },{
      root:null,
      rootMargin:'0px',
      threshold:isMobile() ?  0.1 : .7
    });
    this.observer.observe(groupProfile);
  }

  beforeUnmount(){
    this.observer?.disconnect();
  }
}
